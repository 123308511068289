import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, message, Modal,} from "antd";
import api from "../../../api/api";
import FaceTypeSelect from "../../components/FaceTypeSelect";
import HandSwitch from "../../components/HandSwitch";
import HoldSwitch from "../../components/HoldSwitch";
import PlayerInfo from "../../../data_structure/PlayerInfo";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
export default function ModifyPlayer({visible, close, sumbit, currData}) {
  const [form] = Form.useForm();
  const [backupData, setBackup] = useState(null);
  const onChangeForm = useCallback((key, value) => form.setFieldsValue({[key]: value}), [form]);
  const onFinishModify = async (values) => {
    const result = await api.updatePlayer(backupData, new PlayerInfo(values));
    if (result?.succeed) {
      message.success("修改运动员成功！");
      sumbit();
    } else {
      message.error("修改运动员失败！");
    }
  };
  const onReset = () => form.resetFields();
  useEffect(() => {
    if (currData) {
      if (!backupData) setBackup(currData.copy());
      for (const key in currData)
        if (currData.hasOwnProperty(key)) {
          onChangeForm(key, currData[key]);
        }
    }
  }, [backupData, currData, onChangeForm]);
  return (
    <Modal
      title="修改运动员"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinishModify}
      >
        <Form.Item name="name" label="姓名" rules={[{required: true}]}>
          <Input
            onChange={(e) => {
              onChangeForm("name", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item name="country" label="国家" rules={[{required: true}]}>
          <Input
            onChange={(e) => {
              onChangeForm("country", e.target.value);
            }}
          />
        </Form.Item>

        <Form.Item
          name="faceType0"
          label="反手胶面"
          rules={[{required: true}]}
        >
          <FaceTypeSelect placeholder="请选择反手胶面"
                          onChange={value => onChangeForm("faceType0", value)}/>
        </Form.Item>

        <Form.Item
          name="faceType1"
          label="正手胶面"
          rules={[{required: true}]}
        >
          <FaceTypeSelect placeholder="请选择正手胶面"
                          onChange={value => onChangeForm("faceType1", value)}/>
        </Form.Item>

        <Form.Item
          name="isRight"
          label="惯用右手"
          rules={[{required: true}]}
          checked={currData?.isRight}
        >
          <HandSwitch defaultChecked={currData?.isRight}
                      onChange={value => onChangeForm("isRight", value)}/>
        </Form.Item>
        <Form.Item
          name="isStraight"
          label="惯用直拍"
          rules={[{required: true}]}
        >
          <HoldSwitch defaultChecked={currData?.isStraight}
                      onChange={value => onChangeForm("isStraight", value)}/>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            修改
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
