import {Select} from "antd";

const {Option} = Select;

export default function BooleanSelect(props) {
    return <Select
      showSearch
      className="item"
      allowClear
      optionFilterProp="children"
      {...props}
    >
        <Option value={true}>有</Option>
        <Option value={false}>无</Option>
    </Select>
}
