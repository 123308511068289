import {Switch} from "antd";

export default function HoldSwitch(props) {
    return <Switch
      className="switch"
      checkedChildren="是"
      unCheckedChildren="否"
      defaultChecked={false}
      {...props}
    />
}
