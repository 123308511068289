import React, {useCallback, useEffect, useState} from "react";
import {Button, Form, Input, message, Modal,} from "antd";
import api from "../../../api/api";
import HandSwitch from "./MySwitch";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
function checkTF(value) {
  if(!(typeof value === 'string')){
    return value?'TRUE':'FALSE'
  }
  return value==='true'?'TRUE':value==='false'?'FALSE':value
}

export default function ModifyPlayer({visible, close, sumbit, currData}) {
  const [form] = Form.useForm();
  const [backupData, setBackup] = useState(null);
  const onChangeForm = useCallback((key, value) => form.setFieldsValue({[key]: value}), [form]);
  const onFinishModify = async (values) => {
    // console.log({...values})
    const result = await api.update_player_name_dict(values.no,values.zh_name,values.country,values.en_name,values.Nation,values.isRight,values.isStraight,values.faceType0,values.faceType1,values.gender
        // "no":no,"zh_name":zh_name,"country":country,"en_name":en_name,"Nation":Nation,"isRight":isRight,"isStraight":isStraight,"faceType0":faceType0,"faceType1":faceType1,"gender":gender
  );
    if (result?.succeed) {
      message.success("修改运动员信息成功！");
      sumbit();
    } else {
      message.error("修改运动员信息失败！");
    }
  };
  const onReset = () => form.resetFields();
  useEffect(() => {
    if (currData) {
      if (!backupData) setBackup(currData.copy());
      for (const key in currData)
        if (currData.hasOwnProperty(key)) {
          onChangeForm(key, currData[key]);
        }
    }
  }, [backupData, currData, onChangeForm]);
  return (
    <Modal
      title="修改运动员信息"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form
        {...layout}
        form={form}
        name="control-hooks"
        onFinish={onFinishModify}
      >
        <Form.Item name="no" label="序号" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("no", e.target.value)} disabled />
        </Form.Item>
        <Form.Item name="zh_name" label="中文名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("zh_name", e.target.value)}/>
        </Form.Item>
        <Form.Item name="en_name" label="英文名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("en_name", e.target.value)}/>
        </Form.Item>
        <Form.Item name="country" label="国家" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("country", e.target.value)}/>
        </Form.Item>
        <Form.Item name="gender" label="性别" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("gender", e.target.value)}/>
        </Form.Item>
        <Form.Item name="Nation" label="国家代号" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("Nation", e.target.value)}/>
        </Form.Item>
        <Form.Item
            name="isRight"
            label="惯用右手"
            rules={[{required: true}]}
        >
          <HandSwitch onChange={value => onChangeForm("isRight", checkTF(value))} defaultChecked={currData?.isRight==='TRUE'}/>
        </Form.Item>
        <Form.Item
            name="isStraight"
            label="是否直拍"
            rules={[{required: true}]}
        >
          <HandSwitch onChange={value => onChangeForm("isStraight", checkTF(value))} defaultChecked={currData?.isStraight==='TRUE'}/>
        </Form.Item>
        <Form.Item name="faceType0" label="正手拍面" rules={[{required: false}]}>
          <Input onChange={e => onChangeForm("faceType0", e.target.value)}/>
        </Form.Item>
        <Form.Item name="faceType1" label="反手拍面" rules={[{required: false}]}>
          <Input onChange={e => onChangeForm("faceType1", e.target.value)}/>
        </Form.Item>
        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            修改
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
