import "./App.css";
import {Switch} from "react-router";
import {BrowserRouter} from "react-router-dom";
import "moment/locale/zh-cn";
import RouterComm from "./pages/router";
import {Provider} from "mobx-react";
import dataStore from "../store/DataStore";

export default function App() {
    return <Provider data={dataStore}>
        <BrowserRouter>
            <Switch>
                <RouterComm/>
            </Switch>
        </BrowserRouter>
    </Provider>;
}
