import {action, makeObservable, observable} from "mobx";
import api from "../api/api";

class DataStore {
    players = []
    matches = []
    users = []
    managerLogin = false
    onlyPlayer = false
    setOnlyPlayer = onlyPlayer=>this.onlyPlayer = onlyPlayer;
    setManagerLogin = manager =>this.managerLogin = manager
    setPlayers = newPlayers => this.players = newPlayers;

    constructor() {
        makeObservable(this, {
            players: observable.shallow,
            matches: observable.shallow,
            users: observable.shallow,
            setPlayers: action,
        })

        api.getPlayerList()
          .then(res => this.setPlayers(res))
    }
}

const dataStore = new DataStore();

export default dataStore;
