import React, { useState } from "react";
import { Button, Checkbox, message, Popconfirm, Table } from "antd";
import ModifyMatch from "./modify";
import MatchInfo from "../../../data_structure/MatchInfo";
import api from "../../../api/api";

const mydata = [
    {
        date: "2021-08-90 19:00",
        location: "中国",
        type: "公开赛",
        competition: "男团",
        round: "半决赛",
        players: ["马龙", "许昕"],
        key: 0,
        match_id: 0
    },
];

export default function TableList({ data = mydata, changePage }) {
    const [showModify, setShowModify] = useState(false);
    const [currData, setCurrData] = useState(null);
    const [selectedRowKeys, setSelectedRowKeys] = useState([]);
    const confirmDel = async (currData) => {
        const result = await api.deleteMatch(currData);
        if (result?.succeed) {
            message.success("删除该比赛信息成功！");
            changePage();
        } else {
            message.error("删除该比赛信息失败！");
        }
    };
    async function deleteSelected(selectedRowKeys) {
        const all_match = {}; // 存储所有比赛信息，以 match_id 为键
        for (const d of data) {
            all_match[`${d.match_id}`] = d; // 确保 match_id 转换为字符串
        }

        try {
            for (const key of selectedRowKeys) {
                const result = await api.deleteMatch(new MatchInfo(all_match[key]));
                if (!result.succeed) {
                    message.error("删除部分比赛信息失败！");
                    return; // 退出循环，不再继续删除
                }
                // 如果需要，可以在这里添加删除后的处理逻辑
            }
            message.success("删除选中的比赛信息成功！");
            // 可选：更新页面或表格状态
        } catch (error) {
            message.error("删除比赛信息时发生错误：" + error.message);
        }
        setSelectedRowKeys([]);
        changePage();
    }


    const closeModify = () => setShowModify(false);

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
            setSelectedRowKeys(selectedRowKeys)
        },
        getCheckboxProps: (record) => ({
            disabled: record.name === 'Disabled User',
            // Column configuration not to be checked
            name: record.name,
        }),
    };
    return (
      <>
      {/*    <Button*/}
      {/*    type="danger"*/}
      {/*    disabled={selectedRowKeys.length === 0}*/}
      {/*    onClick={() => deleteSelected(selectedRowKeys)}*/}
      {/*>*/}
      {/*    删除选中项*/}
      {/*</Button>*/}
          <Table
              rowSelection={{...rowSelection}}
              rowKey = {record=>record.key}
            dataSource={data}
            columns={[
                {
                    title: "比赛id",
                    dataIndex: "match_id",
                    key: "match_id",
                },
                {
                    title: "比赛日期",
                    dataIndex: "date",
                    key: "date",
                    defaultSortOrder: 'descend',
                    sorter: (a, b) => a.date - b.date,
                },
                {
                    title: "比赛地点",
                    dataIndex: "location",
                    key: "location",
                },
                {
                    title: "比赛类型",
                    dataIndex: "type",
                    key: "type",
                    render: row => MatchInfo.TYPE.filter(item => item.key === row)[0]?.label,
                },
                {
                    title: "比赛项目",
                    dataIndex: "competition",
                    key: "competition",
                    render: row => MatchInfo.COMPETITION.filter(item => item.key === row)[0]?.label,
                },
                {
                    title: "比赛轮次",
                    key: "round",
                    dataIndex: "round",
                    render: row => MatchInfo.ROUND.filter(item => item.key === row)[0]?.label,
                },
                {
                    title: "参赛球员",
                    dataIndex: "players",
                    key: "players",
                    render: row => row.join(","),
                },
                {
                    title: "是否有数据",
                    dataIndex: "has_data",
                    key: "has_data",
                    render: row => <Checkbox checked={row}/>,
                },
                {
                    title: "是否有视频",
                    dataIndex: "has_video",
                    key: "has_video",
                    render: row => <Checkbox checked={row}/>,
                },
                {
                    title: "是否有纯净版",
                    dataIndex: "has_purevideo",
                    key: "has_purevideo",
                    render: row => <Checkbox checked={row}/>,
                },

                {
                    title: "操作",
                    key: "2",
                    render: curr => {
                        return <>
                            <Button type="link"
                                    onClick={() => {
                                        setShowModify("info");
                                        setCurrData(new MatchInfo(curr));
                                    }}>
                                修改
                            </Button>
                            <Button type="link"
                                    onClick={() => {
                                        setShowModify("video");
                                        setCurrData(new MatchInfo(curr));
                                    }}>
                                修改视频
                            </Button>
                            <Button type="link"
                                    onClick={() => {
                                        setShowModify("data");
                                        setCurrData(new MatchInfo(curr));
                                    }}>
                                修改数据
                            </Button>
                            <Button type="link"
                                    onClick={() => {
                                        setShowModify("purevideo");
                                        setCurrData(new MatchInfo(curr));
                                    }}>
                                修改纯净版
                            </Button>

                            <Popconfirm title="您确定要删除该比赛信息吗?"
                                        onConfirm={() => confirmDel(new MatchInfo(curr))}
                                        okText="确定"
                                        cancelText="取消">
                                <Button type="link" danger>
                                    删除
                                </Button>
                            </Popconfirm>
                        </>;
                    },
                },
            ]}
            pagination={{
                defaultCurrent: 1,
                total: data.length,
                showTotal: total => `共计${total}场比赛`,
            }}
          />
          {showModify && <ModifyMatch
            visible={showModify}
            close={closeModify}
            sumbit={() => {
                closeModify();
                changePage();
            }}
            currData={currData}
          />}
      </>
    );
}
