import React, {useCallback, useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, message, Modal,} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import MatchTypeSelect from "../../components/MatchTypeSelect";
import MatchCompetitionSelect from "../../components/MatchCompetitionSelect";
import MatchRoundSelect from "../../components/MatchRoundSelect";
import api from "../../../api/api";
import MatchInfo from "../../../data_structure/MatchInfo";
import VideoInput from "./VideoInput";
import ProjectInput from "./ProjectInput";
import onProgress from "./progress";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};

export default function ModifyMatch({visible, close, sumbit, currData}) {
  const [form] = Form.useForm();
  const [video, setVideo] = useState(null);
  const [proj, setProj] = useState(null);
  const [pureVideo, setPureVideo] = useState(null)
  const onChangeForm = useCallback((key, value) => form.setFieldsValue({[key]: value}), [form]);
  const onReset = () => form.resetFields();

  const [backupData, setBackup] = useState(null);
  const onFinishModify = async (values) => {
    let succeed = false;
    if (visible === "info") {
      values["date"] = moment(values["date"]).format("YYYYMMDD");
      values["players"] = [
        values["playersA"].split("/"),
        values["playersB"].split("/"),
      ];
      const params = new MatchInfo(values);
      const result = await api.updateMatchInfo(backupData, params);
      succeed = result?.succeed;
    } else if (visible === "video") {
      const result = await api.updateVideo(backupData, video, onProgress(backupData.genName()));
      succeed = result?.succeed;
    } else if (visible === "data") {
      const result = await api.updateData(backupData, proj);
      succeed = result?.succeed;
    } else if (visible === "purevideo") {
      const result = await api.updatePureVideo(backupData, pureVideo, onProgress(backupData.genName()+"（整场纯净版）"));
      succeed = result?.succeed;
    }

    if (succeed) {
      message.success("修改比赛信息成功！");
      sumbit();
    } else message.error("修改比赛信息失败！");
  };
  useEffect(() => {
    if (currData) {
      if (!backupData) setBackup(currData.copy());
      for (const key in currData) {
        if (!currData.hasOwnProperty(key)) continue;
        if (key === "date") {
          let newDate = currData[key].replace(
            /^(\d{4})(\d{2})(\d{2})$/,
            "$1-$2-$3"
          );
          onChangeForm(key, moment(newDate));
        } else if (key === "players") {
          onChangeForm("playersA", currData[key][0].join("/"));
          onChangeForm("playersB", currData[key][1].join("/"));
        } else {
          onChangeForm(key, currData[key]);
        }
      }
    }
  }, [backupData, currData, onChangeForm]);

  return <Modal title="修改比赛"
                visible={Boolean(visible)}
                onOk={sumbit}
                onCancel={close}
                footer={null}>
    <Form {...layout}
          form={form}
          name="control-hooks"
          onFinish={onFinishModify}>
      {visible === "info" && <>
        <Form.Item name="date" label="比赛日期" rules={[{required: true}]}>
          <DatePicker placeholder="请选择比赛日期"
                      size="middle"
                      locale={locale}
                      style={{width: 354}}
                      onChange={date => onChangeForm("date", date)}
          />
        </Form.Item>
        <Form.Item name="location" label="比赛地点" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("location", e.target.value)}/>
        </Form.Item>
        <Form.Item name="type" label="比赛类型" rules={[{required: true}]}>
          <MatchTypeSelect onChange={(value) => {
            onChangeForm("type", value);
          }}/>
        </Form.Item>
        <Form.Item name="competition" label="比赛项目" rules={[{required: true}]}>
          <MatchCompetitionSelect onChange={value => onChangeForm("competition", value)}/>
        </Form.Item>
        <Form.Item name="round" label="比赛轮次" rules={[{required: true}]}>
          <MatchRoundSelect onChange={value => onChangeForm("round", value)}/>
        </Form.Item>
        <Form.Item name="playersA" label="A队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersA", e.target.value)}/>
        </Form.Item>
        <Form.Item name="playersB" label="B队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersB", e.target.value)}/>
        </Form.Item>
      </>}
      {visible === "video" && <Form.Item label={"视频文件"}>
        <VideoInput onChange={setVideo}/>
      </Form.Item>}
      {visible === "data" && <Form.Item label={"数据文件"}>
        <ProjectInput onChange={setProj}/>
      </Form.Item>}
      {visible === "purevideo" && <Form.Item label={"纯净版"}>
        <VideoInput onChange={setPureVideo}/>
      </Form.Item>}

      <Form.Item {...tailLayout}>
        <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
          修改
        </Button>
        <Button htmlType="button" onClick={onReset} style={{margin: "0 20px"}}>
          重置
        </Button>
      </Form.Item>
    </Form>
  </Modal>;
}
