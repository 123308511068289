import React, {useCallback, useEffect} from "react";
import {Button, Form, Input, message, Modal, Switch,} from "antd";
import PlayerNameInfo from "../../../data_structure/PlayerNameInfo";
import api from "../../../api/api";
import HandSwitch from "./MySwitch";

const layout = {
  labelCol: {span: 8},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};

function checkTF(value) {
  if(!(typeof value === 'string')){
    return value?'TRUE':'FALSE'
  }
  return value==='true'?'TRUE':value==='false'?'FALSE':value
}

export default function AddPlayer({visible, close, sumbit}) {
  const [form] = Form.useForm();
  const onChangeForm = (key, value) => {
    form.setFieldsValue({[key]: value});
  };
  const onFinishAdd = async (values) => {
    const params = new PlayerNameInfo(values);
    const result = await api.add_player_name_dict(values.zh_name,values.country,values.en_name,values.Nation,values.isRight,values.isStraight,values.faceType0,values.faceType1,values.gender);
    if (result?.succeed) {
      message.success("增加运动员信息成功！");
      sumbit();
    } else {
      message.error("增加运动员信息失败！");
    }
  };
  const onReset = useCallback(() => form.resetFields(), [form]);
  useEffect(() => onReset(), [onReset, visible]);
  return (
    <Modal
      title="新增运动员信息"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <Form.Item name="zh_name" label="中文名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("zh_name", e.target.value)}/>
        </Form.Item>
        <Form.Item name="en_name" label="英文名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("en_name", e.target.value)}/>
        </Form.Item>
        <Form.Item name="country" label="国家" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("country", e.target.value)}/>
        </Form.Item>
        <Form.Item name="gender" label="性别man/woman" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("gender", e.target.value)}/>
        </Form.Item>
        <Form.Item name="Nation" label="国家代号(3位大写)" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("Nation", e.target.value)}/>
        </Form.Item>
        <Form.Item
            name="isRight"
            label="惯用右手"
            rules={[{required: true}]}
            initialValue={'TRUE'}
        >
          <HandSwitch onChange={value => onChangeForm("isRight", checkTF(value))} defaultChecked/>
        </Form.Item>
        <Form.Item
            name="isStraight"
            label="是否直拍"
            rules={[{required: true}]}
            initialValue={'TRUE'}
        >
          <HandSwitch onChange={value => onChangeForm("isStraight", checkTF(value))} defaultChecked/>
        </Form.Item>
        <Form.Item name="faceType0" label="正手拍面" rules={[{required: false}]}>
          <Input onChange={e => onChangeForm("faceType0", e.target.value)}/>
        </Form.Item>
        <Form.Item name="faceType1" label="反手拍面" rules={[{required: false}]}>
          <Input onChange={e => onChangeForm("faceType1", e.target.value)}/>
        </Form.Item>


        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            新增
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
