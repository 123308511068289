
import React, {useContext, createContext, useState} from "react";
import {
    BrowserRouter as Router,
    Switch,
    Route,
    Link,
    //Redirect重定向组件
    Redirect,
    useHistory,
    useLocation
} from "react-router-dom";
import dataStore from "../../store/DataStore";
//isAuth为判断Token是否存在，即判断用户是否登录

const AuthRoute = ({component: Component, ...res}) => {
    function isAuth() {

        return dataStore.managerLogin;
    }

    return <Route
        {...res}
        render={props => {
            const isLoging = isAuth();

            if (isLoging) {
                //已经登录
                return <Component {...props}/>
            } else {
                //未登录
                return <Redirect to={{
                    pathname: "/login",
                    state: {
                        form: props.location,
                    }
                }}/>
            }
        }}/>
}
export default AuthRoute;