import React, {useCallback, useEffect, useMemo, useState} from "react";
import {Button, Form, Input, message, Modal, Switch,} from "antd";
import api from "../../../api/api";
import UserInfo from "../../../data_structure/UserInfo";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
export default function ModifyUser({visible, close, sumbit, currData}) {
  const [form] = Form.useForm();
  const [backupData, setBackup] = useState(null);
  const permissions = useMemo(() => currData ? Object.keys(currData.permissions) : [], [currData]);
  const onChangeForm = useCallback((key, value) => {
    form.setFieldsValue({[key]: value});
    if (key in permissions) currData.permissions[key] = value;
    else currData[key] = value;
  }, [currData, form, permissions]);
  const onFinishAdd = async (values) => {
    const {username, password,openId} = values;
    const params = {
      username,
      permissions: Object.fromEntries(permissions.map(permission => [permission, values[permission]])),
      openId,
    };
    const result = await api.updateUser(backupData, new UserInfo(params, password));
    if (result?.succeed) {
      message.success("修改用户成功！");
      sumbit();
    } else {
      message.error("修改用户失败！");
    }
  };
  const onReset = () => form.resetFields();
  useEffect(() => {
    if (currData) {
      if (!backupData) setBackup(currData.copy());
      const {username,openId, permissions} = currData;
      for (const key in permissions)
        if (permissions.hasOwnProperty(key)) {
          onChangeForm(key, permissions[key]);
        }
      onChangeForm("username", username);
      onChangeForm("openId",openId)
    }
  }, [backupData, currData, onChangeForm]);

  return (
    <Modal
      title="修改用户"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <Form.Item name="username" label="用户名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("username", e.target.value)}/>
        </Form.Item>
        <Form.Item name="password" label="密码">
          <Input onChange={e => onChangeForm("password", e.target.value)}/>
        </Form.Item>
        <Form.Item name="openId" label="微信绑定">
          <Input onChange={e => onChangeForm("openId", e.target.value)}/>
        </Form.Item>
        {permissions.map(permission => <Form.Item key={permission}
                                                  name={permission}
                                                  label={`${permission}权限`}
                                                  rules={[{required: true}]}>
          <Switch className="switch"
                  checkedChildren="开启"
                  unCheckedChildren="关闭"
                  defaultChecked={currData?.permissions[permission]}
                  onChange={(value) => {
                    onChangeForm(permission, value);
                  }}/>
        </Form.Item>)}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            修改
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
