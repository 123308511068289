import randomFromArray from "../utils/RandomArray";

export default class UserInfo {
    username = "";          // 用户名
    password = "****"       // 密码（后端不会传给前端，但前端可以修改之后发给后端，暂时不考虑加密）
    permissions = {          // 权限，目前是这三个，不排除会扩展。最好按照key来，有几个key显示几个权限的切换按钮
        collector: false,
        analyzer: false,
        experiment: false,
    }
    openId = ""

    constructor(userJson, password=null) {
        this.username = userJson.username;
        if (password !== null)
            this.password = password;
        this.permissions = userJson.permissions;
        this.openId = userJson.openId;
    }

    copy() {
        return new UserInfo({
            username: this.username,
            openId : this.openId,
            permissions: JSON.parse(JSON.stringify(this.permissions))
        }, this.password);
    }

    str() {
        return JSON.stringify(this.json());
    }

    json() {
        return {
            username: this.username,
            password: this.password === "****" ? null : this.password,
            openId: this.openId,
            permissions: this.permissions,
        };
    }

    static random() {
        const allCharacters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        const permissionAlters = [true, false];
        return new UserInfo({
            username: [...new Array(7)]
              .map(_ => randomFromArray(allCharacters))
              .join(),
            openId: "",
            permissions: {
                collector: randomFromArray(permissionAlters),
                analyzer: randomFromArray(permissionAlters),
                experiment: randomFromArray(permissionAlters)
            }
        })
    }
}
