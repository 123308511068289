import React, {useCallback, useEffect} from "react";
import {Button, Form, Input, message, Modal, Switch,} from "antd";
import api from "../../../api/api";
import UserInfo from "../../../data_structure/UserInfo";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
export default function AddUser({visible, close, sumbit, permissions}) {
  const [form] = Form.useForm();
  const onChangeForm = (key, value) => {
    form.setFieldsValue({[key]: value});
  };
  const onFinishAdd = async (values) => {
    const {username, password} = values;
    const params = new UserInfo({
      username,
      permissions: Object.fromEntries(permissions.map(perm => [perm, values[perm]])),
      openId:""
    }, password);
    const result = await api.newUser(params);
    if (result?.succeed) {
      message.success("增加用户成功！");
      sumbit();
    } else {
      message.error("增加用户失败！");
    }
  };
  const onReset = useCallback(() => form.resetFields(), [form]);
  useEffect(() => onReset(), [onReset])

  return (
    <Modal
      title="新增用户"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <Form.Item name="username" label="用户名" rules={[{required: true}]}>
          <Input
            onChange={(e) => {
              onChangeForm("username", e.target.value);
            }}
          />
        </Form.Item>
        <Form.Item name="password" label="密码" rules={[{required: true}]}>
          <Input
            onChange={(e) => {
              onChangeForm("password", e.target.value);
            }}
          />
        </Form.Item>

        {permissions.map(perm => <Form.Item name={perm}
                                            label={`${perm}权限`}
                                            initialValue={perm === "analyzer"}
                                            rules={[{required: true}]}>
          <Switch
            className="switch"
            checkedChildren="开启"
            unCheckedChildren="关闭"
            defaultChecked={perm === "analyzer"}
            onChange={(value) => onChangeForm(perm, value)}
          />
        </Form.Item>)}
        {/*<Form.Item*/}
        {/*  name="collector"*/}
        {/*  label="收集权限"*/}
        {/*  initialValue={true}*/}
        {/*  rules={[{required: true}]}*/}
        {/*>*/}
        {/*  <Switch*/}
        {/*    className="switch"*/}
        {/*    checkedChildren="开启"*/}
        {/*    unCheckedChildren="关闭"*/}
        {/*    defaultChecked*/}
        {/*    onChange={(value) => {*/}
        {/*      onChangeForm("collector", value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  name="analyzer"*/}
        {/*  label="分析权限"*/}
        {/*  initialValue={true}*/}
        {/*  rules={[{required: true}]}*/}
        {/*>*/}
        {/*  <Switch*/}
        {/*    className="switch"*/}
        {/*    checkedChildren="开启"*/}
        {/*    unCheckedChildren="关闭"*/}
        {/*    defaultChecked*/}
        {/*    onChange={(value) => {*/}
        {/*      onChangeForm("analyzer", value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  name="experiment"*/}
        {/*  label="实验性功能"*/}
        {/*  initialValue={true}*/}
        {/*  rules={[{required: true}]}*/}
        {/*>*/}
        {/*  <Switch*/}
        {/*    className="switch"*/}
        {/*    checkedChildren="开启"*/}
        {/*    unCheckedChildren="关闭"*/}
        {/*    defaultChecked*/}
        {/*    onChange={(value) => {*/}
        {/*      onChangeForm("experiment", value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Item>*/}
        {/*<Form.Item*/}
        {/*  name="root"*/}
        {/*  label="管理员权限"*/}
        {/*  rules={[{required: true}]}*/}
        {/*  initialValue={true}*/}
        {/*>*/}
        {/*  <Switch*/}
        {/*    className="switch"*/}
        {/*    checkedChildren="开启"*/}
        {/*    unCheckedChildren="关闭"*/}
        {/*    defaultChecked*/}
        {/*    onChange={(value) => {*/}
        {/*      onChangeForm("root", value);*/}
        {/*    }}*/}
        {/*  />*/}
        {/*</Form.Item>*/}

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            新增
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
