import MatchInfo from "../data_structure/MatchInfo";
import UserInfo from "../data_structure/UserInfo";
import PlayerInfo from "../data_structure/PlayerInfo";
import PlayerNameInfo from "../data_structure/PlayerNameInfo";
import errorInfo from "./errorInfo";
import onProgress from "../ui/pages/match/progress";

const DEBUG = false;
const LOCAL = false;
export const useGER = false;
export const AUTO_TEST = false;

let token = null;
let headers = new Headers();
let isLoggingIn = false;

//region utils

const url = (uri, params = null) => `${LOCAL ? "http://127.0.0.1:5000" : useGER?"https://olyttbackend.sportvis.cn": "https://ttbackend.sportvis.cn"}${uri}${params === null ? "" : "?" + new URLSearchParams(params)}`;

const login = () => new Promise((resolve, reject) => {
    if (token) resolve();
    else if (isLoggingIn) {
        const interval = setInterval(() => {
            if (isLoggingIn) return;
            if (token === null) reject(new Error("未能成功登录"));
            else resolve();
            clearInterval(interval);
        }, 100);
    } else {
        isLoggingIn = true;
        fetch(url("/v1/usr/login"), {
            method: "POST",
            body: JSON.stringify({
                username: "root",
                password: "1Qaz2wsx",
            })
        }).then(res => res.json())
          .then(res => {
              if (!res.succeed) {
                  reject(new Error("未能成功登录"));
              } else {
                  token = res.token;
                  headers.set("Token", token);
                  resolve();
              }
              isLoggingIn = false;
          })
    }
});

const requestWithProgress = (method, uri, form, onProgress) => login().then(() => new Promise((resolve, reject) => {
    let oldLoaded, oldTime;

    const xhr = new XMLHttpRequest();
    xhr.open(method, url(uri), true);
    xhr.setRequestHeader("Token", token);
    xhr.onload = () => {
        onProgress(100, 0, 0);
        resolve(JSON.parse(xhr.response));
    }
    xhr.onerror = e => {
        reject(e);
        console.error(e);
    }
    xhr.upload.onprogress = e => {
        const currentLoaded = e.loaded, currentTime = new Date().getTime();
        const difLoaded = currentLoaded - oldLoaded, difTime = (currentTime - oldTime) / 1000;

        let speed = difLoaded / difTime;
        let leftTime = (e.total - e.loaded) / speed;

        if (onProgress) onProgress(e.loaded / e.total * 100, speed, leftTime);
    };
    xhr.onloadstart = () => {
        oldTime = new Date().getTime();
        oldLoaded = 0;
    };
    xhr.send(form);
}));

const request = (input, init, params) => login().then(() => fetch(url(input, params), Object.assign({}, init, {headers})));
//endregion

const api = {
    local: LOCAL,
    //region test
    test: () => {
        api.getMatchList()
          .then(players => console.log(players))
          .catch(console.error);
        api.getUserList()
          .then(players => console.log(players))
          .catch(console.error);
        api.getPlayerList()
          .then(players => console.log(players))
          .catch(console.error);
    },
    //endregion

    //region matches
    /**
     * @callback onUploadDataProgress
     * @param {string} message: status text
     * @param {number} progress: 0~100
     */
    /**
     * @param {MatchInfo} matchInfo
     * @param {File} videoFile
     * @param {File} dataFile
     * @param pureVideoFile
     * @param {onUploadDataProgress} onProgress
     */
    newMatch: (matchInfo, videoFile = null, dataFile = null, pureVideoFile = null,onProgress = null) => {
        console.log("api.newMatch:", matchInfo, videoFile, dataFile,pureVideoFile);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}));

        const form = new FormData();
        form.append("matchInfo", matchInfo.str());
        if (videoFile !== null)
            form.append("videoFile", videoFile);
        if (dataFile !== null)
            form.append("dataFile", dataFile);
        if (pureVideoFile !== null)
            form.append("pureVideoFile", pureVideoFile);

        return requestWithProgress(
          "put",
          "/mng/match",
          form,
          progress => onProgress("Create match...", progress)
        );
    },

    /**
     * @param {MatchInfo} matchInfo
     */
    deleteMatch: matchInfo => {
        console.log('typeof',typeof matchInfo)
        // # TODO
        console.log("api.deleteMatch:", matchInfo,'typeof',typeof matchInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        console.log(matchInfo.json());
        return request("/mng/match", {
            method: "DELETE"
        }, matchInfo.json()).then(res => res.json());
    },
    update_audio_format:()=>{
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/audio_trans_aac",{
            method: "POST",
        },null).then(res => res.json());
    },
    /**
     * @param {MatchInfo} oldMatchInfo
     * @param {MatchInfo} matchInfo
     */
    updateMatchInfo: (oldMatchInfo, matchInfo) => {
        console.log("api.updateMatchInfo:", oldMatchInfo, matchInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/match", {
            method: "POST",
            body: JSON.stringify({
                original: oldMatchInfo.json(),
                new: matchInfo.json(),
            })
        }).then(res => res.json());
    },
    /**
     * @param {MatchInfo} matchInfo
     * @param {File} videoFile
     * @param {onUploadDataProgress} onProgress
     */
    updateVideo: (matchInfo, videoFile, onProgress = null) => {
        console.log("api.updateVideo:", matchInfo, videoFile);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))

        const form = new FormData();
        form.append("matchInfo", matchInfo.str());
        form.append("videoFile", videoFile);
        return requestWithProgress(
          "post",
          "/mng/match/video",
          form,
          progress => onProgress("Uploading video...", progress)
        );
    },
    updatePureVideo: (matchInfo, pureVideoFile, onProgress = null) => {
        console.log("api.updatePureVideo:", matchInfo, pureVideoFile);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))

        const form = new FormData();
        form.append("matchInfo", matchInfo.str());
        form.append("pureVideoFile", pureVideoFile);
        return requestWithProgress(
            "post",
            "/mng/match/purevideo",
            form,
            progress => onProgress("Uploading pure video...", progress)
        );
    },
    /**
     * @param {MatchInfo} matchInfo
     * @param {File} dataFile
     */
    updateData: (matchInfo, dataFile) => {
        console.log("api.updateData:", matchInfo, dataFile);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        const form = new FormData();
        form.append("matchInfo", matchInfo.str());
        form.append("dataFile", dataFile);
        return requestWithProgress(
          "post",
          "/mng/match/data",
          form,
          progress => onProgress("Uploading file...", progress)
        );
    },

    /**
     * @param {number|null} start: the start index (null for no limit)
     * @param {number|null} end: the end index (null for no limit)
     */
    getMatchList: (start = null, end = null) => {
        console.log("api.getMatchList");

        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;

        return request("/v1/matchesid", null, params)
          .then(res => res.json())
          .then(res => new Promise((resolve, reject) => {
              // console.log('res',res.info)
              if (res.succeed) resolve(res.info.map(m => new MatchInfo({'match_id':m.m_id,...m})));
              else reject(res.info);
          }));
    },
    //endregion

    //region users
    /**
     * @param {UserInfo} userInfo
     */
    newUser: userInfo => {
        console.log("api.newUser:", userInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/user", {
            method: "PUT",
            body: userInfo.str(),
        }).then(res => res.json());
    },

    /**
     * @param {UserInfo} userInfo
     */
    deleteUser: userInfo => {
        console.log("api.deleteUser:", userInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/user", {
            method: "DELETE",
        }, userInfo.json()).then(res => res.json());
    },

    /**
     * @param {UserInfo} oldUserInfo
     * @param {UserInfo} userInfo
     */
    updateUser: (oldUserInfo, userInfo) => {
        console.log("api.updateUser:", oldUserInfo, userInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/user", {
            method: "POST",
            body: JSON.stringify({
                original: oldUserInfo.json(),
                new: userInfo.json(),
            })
        }).then(res => res.json());
    },

    /**
     * @param {number|null} start: the start index (null for no limit)
     * @param {number|null} end: the end index (null for no limit)
     */
    getUserList: (start = null, end = null) => {
        console.log("api.getUserList");

        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;

        return request("/mng/users", null, params)
          .then(res => res.json())
          .then(res => new Promise((resolve, reject) => {
              if (res.succeed) resolve(res.info.map(u => new UserInfo(u)));
              else reject(res.info);
          }));
    },
    //endregion

    //region players
    /**
     * @param {PlayerInfo} playerInfo
     */
    newPlayer: playerInfo => {
        console.log("api.newPlayer:", playerInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/player", {
            method: "PUT",
            body: playerInfo.json(),
        }).then(res => res.json());
    },

    /**
     * @param {PlayerInfo} playerInfo
     */
    deletePlayer: playerInfo => {
        console.log("api.deletePlayer:", playerInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/player", {
            method: "DELETE"
        }, playerInfo.json()).then(res => res.json());
    },

    /**
     * @param {PlayerInfo} oldPlayerInfo
     * @param {PlayerInfo} playerInfo
     */
    updatePlayer: (oldPlayerInfo, playerInfo) => {
        console.log("api.updatePlayer:", oldPlayerInfo, playerInfo);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/player", {
            method: "POST",
            body: JSON.stringify({
                original: oldPlayerInfo.json(),
                new: playerInfo.json(),
            })
        }).then(res => res.json());
    },

    /**
     * @param {number|null} start: the start index (null for no limit)
     * @param {number|null} end: the end index (null for no limit)
     */
    getPlayerList: (start = null, end = null) => {
        console.log("api.getPlayersList");

        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;

        return request("/mng/players", null, params)
          .then(res => res.json())
          .then(res => new Promise((resolve, reject) => {
              if (res.succeed) resolve(res.info.map(p => new PlayerInfo(p)));
              else reject(res.info);
          }));
    },
    getPlayerNameList: (start = null, end = null) => {
        console.log("api.player_name_dict");

        const params = (start === null && end === null) ? null : {};
        if (start !== null) params.start = start;
        if (end !== null) params.end = end;

        return request("/v1/player_name_dict", null, params)
            .then(res => res.json())
            .then(res => new Promise((resolve, reject) => {
                if (res.succeed) resolve(res.info.map(p => new PlayerNameInfo(p)));
                else reject(res.info);
            }));
    },

    update_player_name_dict: (no,zh_name,country,en_name,Nation,isRight,isStraight,faceType0,faceType1,gender)=>{
        console.log("api.update_player_name_dict:",no,zh_name,country,en_name,Nation,isRight,isStraight,faceType0,faceType1,gender);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/update_player_name_dict", {
            method: "POST",
            body: JSON.stringify({
                "no":no,"zh_name":zh_name,"country":country,"en_name":en_name,"Nation":Nation,"isRight":isRight,"isStraight":isStraight,
                "faceType0":faceType0,"faceType1":faceType1,"gender":gender
            })
        }).then(res => res.json());
    },
    add_player_name_dict: (zh_name,country,en_name,Nation,isRight,isStraight,faceType0,faceType1,gender)=>{
        console.log("api.add_player_name_dict:", zh_name,country,en_name,Nation,isRight,isStraight,faceType0,faceType1,gender);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/add_player_name", {
            method: "POST",
            body: JSON.stringify({
                "zh_name":zh_name,"country":country,"en_name":en_name,"Nation":Nation,"isRight":isRight,"isStraight":isStraight,
                "faceType0":faceType0,"faceType1":faceType1,"gender":gender
            })
        }).then(res => res.json());
    },
    // deletePlayerName: (en_name,zh_name,country)=>{
    //     console.log("api.deletePlayerName:", en_name,zh_name,country);
    //     if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
    //     return request("/mng/del_player_name", {
    //         method: "POST",
    //         body: JSON.stringify({
    //             en_name:en_name,zh_name:zh_name,country:country
    //         })
    //     }).then(res => res.json());
    // },
    deletePlayerName: no => {
        console.log("api.deletePlayer:", no);
        if (DEBUG) return new Promise(resolve => resolve({succeed: true}))
        return request("/mng/del_player_name", {
            method: "DELETE"
        }, {no:no}).then(res => res.json());
    },

    //endregion
}

export default api;
export {
    errorInfo
};
