import {Switch} from "antd";

export default function HandSwitch(props) {
    return <Switch
        className="switch"
        checkedChildren="是"
        unCheckedChildren="否"
        {...props}
    />
}
