import "antd/dist/antd.css";
import Navigation from "../navigation";
import Match from "./match";
import User from "./user";
import Player from "./player";
import PlayerName from "./player_name"
import {Route} from "react-router";
import Login from "./login";
import AuthRoute from "./AuthRoute";

export default function RouterComm() {
  return <Navigation >
     <Route exact path="/" component={Login} />
    <Route exact path="/login" component={Login} />
    <AuthRoute exact path="/match" component={Match}/>
    <AuthRoute exact path="/player_name" component={PlayerName}/>

    <AuthRoute exact path="/user" component={User}/>
    {/*<AuthRoute exact path="/player" component={Player}/>*/}
  </Navigation>;
}
