import PlayerInfo from "../../data_structure/PlayerInfo";
import {Select} from "antd";

const {Option} = Select;

export default function FaceTypeSelect(props) {
    return <Select
      defaultValue={1}
      allowClear
      {...props}
    >
        {PlayerInfo.FACE_TYPE.map((faceType, faceTypeId) => {
            return (
              <Option value={faceTypeId} key={faceTypeId}>
                  {faceType}
              </Option>
            );
        })}
    </Select>
}
