import React, {useEffect, useState} from "react";
import {Button, DatePicker, Input, message} from "antd";
import TableList from "./table";
import "./index.css";
import AddMatch from "./add";
import locale from 'antd/es/date-picker/locale/zh_CN';
import moment from "moment";
import MatchTypeSelect from "../../components/MatchTypeSelect";
import MatchCompetitionSelect from "../../components/MatchCompetitionSelect";
import MatchRoundSelect from "../../components/MatchRoundSelect";
import api, {errorInfo, useGER} from "../../../api/api";
import BooleanSelect from "../../components/BooleanSelect";
import dataStore from "../../../store/DataStore";

const {Search} = Input;
export default function Match() {
  const [tableData, setTableData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const [filter_match_type,set_filter_match_type] = useState('')
  const [filter_match_comp,set_filter_match_comp] = useState('')
  const [filter_match_round,set_filter_match_round] = useState('')

  const getList = async () => {
    try {
      const result = await api.getMatchList();
      setTableData(result);
      setOriginData(result);
    } catch (e) {
      message.error(errorInfo(e));
    }
  };
  const close = () => setShowAdd(false);
  const filterTable = (key, values) => {
    console.log(key, values);
    if (values !== null && values !== undefined) {
      const filteredData = Array.from(tableData).filter(item => {
        if (key === "players") {
          const pList = values.split(" ");
          return pList.every(onePlayer => item.players.some(side => side.some(p => p.includes(onePlayer))));
        } else {
          if (values === 'all'){
            console.log('all')
            return true
          }
          return item[key] === values;}
      });
      setTableData(Array.from(filteredData));
    } else {
      setTableData(Array.from(originData));
    }
  };
  useEffect(() => getList(), []);
  return (
    <>
      {(!dataStore.onlyPlayer)?
      <>
        <div className="header-content">比赛管理{useGER?'-欧洲':'-杭州'}</div>
        <div className="main-content">
            <div className="match-btns">
              <DatePicker
                  className="item"
                  locale={locale}
                  placeholder="选择比赛日期"
                  onChange={date => filterTable("date", moment(date).format("YYYYMMDD"))}
              />
              <Search
                  placeholder="输入比赛地点"
                  className="item"
                  style={{width: 200}}
                  onSearch={(value) => filterTable("location", value)}
              />
              <MatchTypeSelect style={{width: 200}} value={filter_match_type}
                               onChange={value => {set_filter_match_type(value);filterTable("type", value)}}/>
              <MatchCompetitionSelect style={{width: 200}} value={filter_match_comp}
                                      onChange={value => {set_filter_match_comp(value);filterTable("competition", value)}}/>
              <MatchRoundSelect style={{width: 200}} value={filter_match_round}
                                onChange={value => {set_filter_match_round(value);filterTable("round", value);}}/>
              <Search
                  placeholder="输入参赛球员"
                  className="item"
                  style={{width: 200}}
                  onSearch={(value) => filterTable("players", value)}
              />
              <BooleanSelect style={{width: 200}}
                             placeholder="是否有数据"
                             onChange={value => filterTable("has_data", value)}/>
              <BooleanSelect style={{width: 200}}
                             placeholder="是否有视频"
                             onChange={value => filterTable("has_video", value)}/>
              <BooleanSelect style={{width: 200}}
                             placeholder="是否有纯净版"
                             onChange={value => filterTable("has_purevideo", value)}/>

              <Button
                  type="primary"
                  className="item"
                  onClick={() => setShowAdd(true)}
              >
                新增比赛
              </Button>
              <Button
                  className="item"
                  type="primary"
                  onClick={() => {set_filter_match_round('');
                    set_filter_match_type('');
                    set_filter_match_comp('');setTableData(originData)}}
              >
                重置过滤
              </Button>
              <Button
                  className="item"
                  type="primary"
                  onClick={() => {api.update_audio_format()}}
              >
                音频检查（苹果微信）
              </Button>

            </div>
            <TableList data={tableData.map(r=>{
              return {'key':r.match_id,...r}
            })} changePage={getList}/>
          </div>

          {showAdd && <AddMatch
            visible={showAdd}
          close={close}
          sumbit={() => {
            close();
            getList();
          }}
          />
        }</>:null
      }

  </>
  );
}
