import React, {useState} from "react";
import {Button, message, Popconfirm, Table} from "antd";
import ModiyMatch from "./modify";
import api from "../../../api/api";

export default function TableList({data = [], changePage}) {
    const [showModify, setShowModify] = useState(false);
    const [currData, setCurrData] = useState(null);
    const confirmDel = async (userInfo) => {
        const result = await api.deleteUser(userInfo);
        if (result?.succeed) {
            message.success("删除用户成功！");
            changePage();
        } else {
            message.error("删除用户失败！");
        }
    };
    const permissions = data[0] ? Object.keys(data[0].permissions) : [];
    const closeModify = () => {
        setShowModify(false);
    };
    return (
      <>
          <Table
            dataSource={data}
            columns={[
                {
                    title: "用户名",
                    dataIndex: "username",
                    key: "username",
                },
                {
                    title: "微信绑定(openId码)",
                    dataIndex: "openId",
                    key: "openId",
                },
                ...permissions.map(permission => ({
                    title: `${permission}权限`,
                    key: permission,
                    render: ({permissions: {[permission]: hasPermission}}) => hasPermission ? "有" : "无",
                })),
                {
                    title: "操作",
                    key: "2",
                    render: (curr) => {
                        return (
                          <>
                              <Button
                                type="link"
                                onClick={() => {
                                    setShowModify(true);
                                    setCurrData(curr);
                                }}
                              >
                                  修改
                              </Button>
                              <Popconfirm
                                title="您确定要删除该用户吗?"
                                onConfirm={() => confirmDel(curr)}
                                okText="确定"
                                cancelText="取消"
                              >
                                  <Button type="link" danger>
                                      删除
                                  </Button>
                              </Popconfirm>
                          </>
                        );
                    },
                },
            ]}
            pagination={{
                defaultCurrent: 1,
                total: data.length,
                showTotal: total => `共计${total}名用户`,
            }}
          />
          {showModify && <ModiyMatch
            visible={showModify}
            close={closeModify}
            sumbit={() => {
                closeModify();
                changePage();
            }}
            currData={currData}
          />}
      </>
    );
}
