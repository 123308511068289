import React, {useCallback, useEffect, useState} from "react";
import {Button, DatePicker, Form, Input, message, Modal,} from "antd";
import moment from "moment";
import locale from "antd/es/date-picker/locale/zh_CN";
import MatchTypeSelect from "../../components/MatchTypeSelect";
import MatchCompetitionSelect from "../../components/MatchCompetitionSelect";
import MatchRoundSelect from "../../components/MatchRoundSelect";
import api from "../../../api/api";
import MatchInfo from "../../../data_structure/MatchInfo";
import VideoInput from "./VideoInput";
import ProjectInput from "./ProjectInput";
import onProgress from "./progress";
import {inject, observer} from "mobx-react";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
export default inject('data')(observer(function AddMatch({visible, close, sumbit, data: dataStore}) {
  const [form] = Form.useForm();
  const [videoFile, setVideoFile] = useState(null);
  const [pureVideoFile, setPureVideoFile] = useState(null);
  const [projFile, setProjFile] = useState(null);
  const onChangeForm = (key, value) => form.setFieldsValue({[key]: value});
  const onFinishAdd = async (values) => {
    const params = new MatchInfo({
      date: moment(values["date"]).format("YYYYMMDD"),
      location: values.location,
      type: values.type,
      competition: values.competition,
      round: values.round,
      players: [
        values["playersA"].split("/"),
        values["playersB"].split("/"),
      ]
    });
    const result = await api.newMatch(params, videoFile, projFile,pureVideoFile, onProgress(params.genName()));
    if (result?.succeed) {
      message.success("增加比赛信息成功！");
      sumbit();
    } else message.error("增加比赛信息失败！");
  };
  const onReset = useCallback(() => form.resetFields(), [form]);

  const autoFill = useCallback(matchInfo => {
    form.setFieldsValue({
      date: moment(matchInfo.date.replace(
        /^(\d{4})(\d{2})(\d{2})$/,
        "$1-$2-$3"
      )),
      location: matchInfo.location,
      type: matchInfo.type,
      competition: matchInfo.competition,
      round: matchInfo.round,
      playersA: matchInfo.players[0].join("/"),
      playersB: matchInfo.players[1].join("/"),
    })
  }, [form]);
  const setProj = useCallback(projFile => {
    if (!projFile) {
      setProjFile(null);
      return;
    }
    setProjFile(projFile);
    MatchInfo.fromFile(projFile).then(res => autoFill(res));
  }, [autoFill]);
  const setVideo = useCallback(videoFile => {
    if (!videoFile) {
      setVideoFile(null);
      return;
    }
    setVideoFile(videoFile);
    if (projFile === null)
      autoFill(MatchInfo.fromName(videoFile.name, dataStore.players));
  }, [autoFill, dataStore.players, projFile]);
  const setPureVideo = useCallback(pureVideoFile => {
    if (!pureVideoFile) {
      setPureVideoFile(null);
      return;
    }
    setPureVideoFile(pureVideoFile);
    if (projFile === null)
      autoFill(MatchInfo.fromName(pureVideoFile.name, dataStore.players));
  }, [autoFill, dataStore.players, projFile]);


  useEffect(() => onReset(), [onReset, visible]);
  return (
    <Modal title="新增比赛" visible={visible} footer={null}
           onOk={sumbit} onCancel={close}>
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <Form.Item name="date" label="比赛日期" rules={[{required: true}]}>
          <DatePicker
            placeholder="请选择比赛日期"
            size="middle"
            locale={locale}
            style={{width: 354}}
            onChange={date => onChangeForm("date", date)}
          />
        </Form.Item>
        <Form.Item name="location" label="比赛地点" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("location", e.target.value)}/>
        </Form.Item>
        <Form.Item name="type" label="比赛类型" rules={[{required: true}]}>
          <MatchTypeSelect onChange={value => onChangeForm("type", value)}/>
        </Form.Item>
        <Form.Item name="competition" label="比赛项目" rules={[{required: true}]}>
          <MatchCompetitionSelect onChange={value => onChangeForm("competition", value)}/>
        </Form.Item>
        <Form.Item name="round" label="比赛轮次" rules={[{required: true}]}>
          <MatchRoundSelect onChange={value => onChangeForm("round", value)}/>
        </Form.Item>
        <Form.Item name="playersA" label="A队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersA", e.target.value)}/>
        </Form.Item>
        <Form.Item name="playersB" label="B队球员" rules={[{required: true}]}
                   extra={"单名球员直接输入，两名球员用/分开"}>
          <Input onChange={e => onChangeForm("playersB", e.target.value)}/>
        </Form.Item>
        <Form.Item label={"数据文件"}>
          <ProjectInput onChange={setProj}/>
        </Form.Item>
        <Form.Item label={"视频文件"}>
          <VideoInput onChange={setVideo}/>
        </Form.Item>
        <Form.Item label={"纯净版视频"}>
          <VideoInput onChange={setPureVideo}/>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            确认
          </Button>
          <Button htmlType="button" onClick={onReset} style={{margin: "0 20px"}}>
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}))
