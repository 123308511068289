import MatchInfo from "../../data_structure/MatchInfo";
import {Select} from "antd";
const {Option} = Select;

export default function MatchRoundSelect(props) {
    return <Select
      showSearch
      className="item"
      placeholder="选择比赛轮次"
      optionFilterProp="children"
      {...props}
    >
    {/*    {*/}
    {/*    <Option key={'all'} value={'all'}>{'all'}</Option>*/}
    {/*}*/}
        {MatchInfo.ROUND.map((item) => {
            return <Option key={item.key} value={item.key}>{item.label}</Option>;
        })}
    </Select>
}
