import {Button, Checkbox, Form, Input, message} from "antd";
import dataStore from "../../../store/DataStore";
import usehttpimg from "../../../static/use_of_http.png"
import api from "../../../api/api"
import {useGER} from "../../../api/api";
export default function Login() {
    const onFinish = (values) => {
        fetch(api.local?'http://127.0.0.1:5000/v1/':'https://ttbackend.sportvis.cn/v1/').then(
            data=>{return data.text()}
        ).then(res=>{
            if (values.username==="zhouzheng" && values.password===String(res).split('!')[0]+"2023"){
                dataStore.setManagerLogin(true)
                message.success("登录成功")
            }else if (values.username==="player" && values.password==='data'){
                dataStore.setManagerLogin(true)
                dataStore.setOnlyPlayer(true)
                message.success("登录成功,仅限维护球员数据")
            }else{
                message.error("密码错误")
            }
        })

        // console.log('Success:', values);
    };
    const onFinishFailed = (errorInfo) => {
        console.log('Failed:', errorInfo);
    };
    return(
        <div style={{textAlign:'center',margin:30}}>
    <Form
        name="basic"
        labelCol={{
            span: 8,
        }}
        wrapperCol={{
            span: 16,
        }}
        style={{
            maxWidth: 600,
        }}
        initialValues={{
            remember: true,
        }}
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        autoComplete="off"
    >
        <Form.Item
            label="Username"
            name="username"
            rules={[
                {
                    required: true,
                    message: 'Please input your username!',
                },
            ]}
        >
            <Input />
        </Form.Item>

        <Form.Item
            label="Password"
            name="password"
            rules={[
                {
                    required: true,
                    message: 'Please input your password!',
                },
            ]}
        >
            <Input.Password />
        </Form.Item>

        <Form.Item
            name="remember"
            valuePropName="checked"
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
        >
            {/*<Checkbox>Remember me</Checkbox>*/}
        </Form.Item>

        <Form.Item
            wrapperCol={{
                offset: 8,
                span: 16,
            }}
        >
            <Button type="primary" htmlType="submit">
                Submit
            </Button>
        </Form.Item>
    </Form>
            <div>
                <h4>现在不再需要设置“不安全内容”了，可以使用https开头的网址访问。</h4>
                <h2>{useGER?'欧洲服务器':'杭州服务器'}</h2>
            </div>
        </div>
    )
}
