import React from "react";
import {Layout, Menu} from "antd";
import {DribbbleOutlined, UsergroupAddOutlined, UserOutlined,} from "@ant-design/icons";
import "./index.css";
import {Link} from "react-router-dom";

const {Header, Content, Sider} = Layout;

export default function Navigation(props) {
  return (
    <>
      <div className="header">
        <div className="logo">比赛系统</div>
      </div>
      <Layout style={{minHeight: "100vh", marginTop: -60}}>
        <Sider collapsible className="layout-side">
          <Menu theme="dark" mode="inline">
            <Menu.Item key="1" icon={<DribbbleOutlined/>}>
              <Link to="/match">比赛管理</Link>
            </Menu.Item>
            <Menu.Item key="2" icon={<DribbbleOutlined/>}>
              <Link to="/player_name">运动员姓名管理</Link>
            </Menu.Item>
            <Menu.Item key="3" icon={<UsergroupAddOutlined/>}>
              <Link to="/user">用户管理</Link>
            </Menu.Item>
            {/*<Menu.Item key="3" icon={<UserOutlined/>}>*/}
            {/*  <Link to="/player">运动员管理</Link>*/}
            {/*</Menu.Item>*/}
          </Menu>
        </Sider>
        <Layout className="site-layout">
          <Header className="site-layout-background" style={{padding: 0}}/>
          <Content>{props.children}</Content>
        </Layout>
      </Layout>
    </>
  );
}
