import React, {useState} from "react";
import {Button, message, Popconfirm, Table} from "antd";
import ModifyPlayer from "./modify";
import api from "../../../api/api";

export default function TableList({data = [], changePage}) {
    const [showModify, setShowModify] = useState(false);
    const [currData, setCurrData] = useState(null);
    const confirmDel = async (playerData) => {
        const result = await api.deletePlayerName(playerData["no"]);
        if (result?.succeed) {
            message.success("删除运动员信息成功！");
            changePage();
        } else {
            message.error("删除运动员信息失败！");
        }
    };
    const closeModify = () => {
        setShowModify(false);
    };
    return (
      <>
          <Table
            dataSource={data}
            columns={[
                {
                    title: "序号",
                    dataIndex: "no",
                    key: "no",
                },
                {
                    title: "中文名",
                    dataIndex: "zh_name",
                    key: "zh_name",
                },
                {
                    title: "英文名",
                    dataIndex: "en_name",
                    key: "en_name",
                },
                {
                    title: "国家",
                    dataIndex: "country",
                    key: "country",
                },
                {
                    title: "性别",
                    dataIndex: "gender",
                    key: "gender",
                },
                {
                    title: "国家代号",
                    dataIndex: "Nation",
                    key: "Nation",
                },
                {
                    title: "惯用右手",
                    dataIndex: "isRight",
                    key: "isRight",
                    // render: (isRight) => {
                    //     return isRight==='true'||isRight==='TRUE' ? "是" :isRight==='false'||isRight==='FALSE' ?"否":"";
                    // },
                },{
                    title: "是否直拍",
                    dataIndex: "isStraight",
                    key: "isStraight",
                    // render: (isStraight) => {
                    //     return isStraight==='true'||isStraight==='TRUE' ?  "是" :isStraight==='false'||isStraight==='FALSE' ?"否":"";
                    // },
                },
                {
                    title: "正手拍面",
                    dataIndex: "faceType0",
                    key: "faceType0",
                },
                {
                    title: "反手拍面",
                    dataIndex: "faceType1",
                    key: "faceType1",
                },
                {
                    title: "操作",
                    key: "2",
                    render: (curr, data) => {
                        return (
                          <>
                              <Button
                                type="link"
                                onClick={() => {
                                    setShowModify(true);
                                    setCurrData(curr);
                                }}
                              >
                                  修改
                              </Button>
                              <Popconfirm
                                title="您确定要删除该运动员姓名吗?"
                                onConfirm={() => confirmDel(curr)}
                                okText="确定"
                                cancelText="取消"
                              >
                                  <Button type="link" danger>
                                      删除
                                  </Button>
                              </Popconfirm>
                          </>
                        );
                    },
                },
            ]}
            pagination={{
                defaultCurrent: 1,
                total: data.length,
                showTotal: total => `共计${total}名球员`,
            }}
          />
          {showModify && <ModifyPlayer
            visible={showModify}
            close={closeModify}
            sumbit={() => {
                closeModify();
                changePage();
            }}
            currData={currData}
          />}
      </>
    );
}
