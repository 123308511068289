import randomFromArray from "../utils/RandomArray";

export default class PlayerNameInfo {

    no="";
    zh_name = "";
    country="";
    en_name="";
    Nation="";
    isRight = true;         // 惯用手是否为右手
    isStraight = false;     // 持拍方式是否为直拍（另一个是横拍）
    faceType0 = "";          // 反手胶面
    faceType1 = "";          // 正手胶面
    gender="";
    static FACE_TYPE = ["正胶", "反胶", "生胶", "长胶"];



    constructor(playerJson) {
        this.no = playerJson.no
        this.zh_name = playerJson.zh_name;
        this.en_name = playerJson.en_name;
        this.country = playerJson.country;
        this.Nation = playerJson.Nation;
        this.isRight = playerJson.isRight;
        this.isStraight = playerJson.isStraight;
        this.faceType0 = playerJson.faceType0;
        this.faceType1 = playerJson.faceType1;
        this.gender = playerJson.gender;
    }

    copy() {
        return new PlayerNameInfo(this);
    }

    str() {
        return JSON.stringify(this.json());
    }

    json() {
        return {
            no:this.no,
            zh_name : this.zh_name,
            country : this.country,
            en_name : this.en_name,
            Nation : this.Nation,
            isRight : this.isRight,
            isStraight : this.isStraight,
            faceType0 : this.faceType0,
            faceType1 : this.faceType1,
            gender : this.gender,
        }
    }


}
