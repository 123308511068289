import React, {useEffect, useState} from "react";
import {Button, Input, message} from "antd";
import TableList from "./table";
import "./index.css";
import AddMatch from "./add";
import api, {errorInfo} from "../../../api/api";
import {inject, observer} from "mobx-react";

const {Search} = Input;

export default inject('data')(observer(function Match({data: dataStore}) {
    const [tableData, setTableData] = useState([]);
    const [originData, setOriginData] = useState([]);
    const [showAdd, setShowAdd] = useState(false);
    const getList = async () => {
        try {
            const result = await api.getPlayerList();
            setTableData(result);
            setOriginData(result);
            dataStore.setPlayers(result);
        } catch (e) {
            message.error(errorInfo(e));
        }
    };
    const close = () => setShowAdd(false);
    const filterTable = (key, values) => {
        if (!values) setTableData(originData);
        else if (key !== "name") setTableData(originData.filter(item => item[key] === values));
        else setTableData(originData.filter(item => item[key].includes(values) || values.includes(item[key])));
    };
    useEffect(() => getList(), []);
    return (
      <>
          <div className="header-content">运动员管理</div>
          <div className="main-content">
              <div className="match-btns">
                  <Search
                    className="item"
                    placeholder="输入运动员名称"
                    style={{width: 200}}
                    onSearch={value => filterTable("name", value)}
                  />
                  <Search
                    className="item"
                    placeholder="输入运动员国家"
                    style={{width: 200}}
                    onSearch={value => filterTable("country", value)}
                  />
                  <Button
                    type="primary"
                    className="item"
                    onClick={() => setShowAdd(true)}
                  >
                      新增运动员
                  </Button>
              </div>
              <TableList data={tableData} changePage={getList}/>
          </div>
          {showAdd && <AddMatch
            visible={showAdd}
            close={close}
            sumbit={() => {
                close();
                getList();
            }}
          />}
      </>
    );
}))
