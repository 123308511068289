import randomFromArray from "../utils/RandomArray";

export default class PlayerInfo {
    name = "";              // 姓名
    country = "";           // 国家
    isRight = true;         // 惯用手是否为右手
    isStraight = false;     // 持拍方式是否为直拍（另一个是横拍）
    faceType0 = 1;          // 反手胶面
    faceType1 = 1;          // 正手胶面（0，1，2，3对应下方FACE_TYPE里的中文）

    static FACE_TYPE = ["正胶", "反胶", "生胶", "长胶"];

    constructor(playerJson) {
        this.name = playerJson.name;
        this.country = playerJson.country;
        this.isRight = playerJson.isRight;
        this.isStraight = playerJson.isStraight;
        this.faceType0 = playerJson.faceType0;
        this.faceType1 = playerJson.faceType1;
    }

    copy() {
        return new PlayerInfo(this);
    }

    str() {
        return JSON.stringify(this.json());
    }

    json() {
        return {
            name: this.name,
            country: this.country,
            isRight: this.isRight,
            isStraight: this.isStraight,
            faceType0: this.faceType0,
            faceType1: this.faceType1,
        }
    }

    static random() {
        const countries = ["中国", "韩国", "奥地利", "德国"];
        const players = ["马龙", "许昕", "王楚钦", "刘诗雯", "丁宁", "伊藤美诚", "平野美宇", "波尔", "张本智和"];
        const bools = [false, true];

        return new PlayerInfo({
            name: randomFromArray(players, false),
            country: randomFromArray(countries),
            isRight: randomFromArray(bools),
            isStraight: randomFromArray(bools),
            faceType0: randomFromArray(PlayerInfo.FACE_TYPE.map((_, i) => i)),
            faceType1: randomFromArray(PlayerInfo.FACE_TYPE.map((_, i) => i)),
        })
    }
}
