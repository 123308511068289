import React, {useCallback, useEffect} from "react";
import {Button, Form, Input, message, Modal,} from "antd";
import PlayerInfo from "../../../data_structure/PlayerInfo";
import api from "../../../api/api";
import FaceTypeSelect from "../../components/FaceTypeSelect";
import HandSwitch from "../../components/HandSwitch";
import HoldSwitch from "../../components/HoldSwitch";

const layout = {
  labelCol: {span: 4},
  wrapperCol: {span: 18},
};
const tailLayout = {
  wrapperCol: {offset: 4, span: 24},
};
export default function AddPlayer({visible, close, sumbit}) {
  const [form] = Form.useForm();
  const onChangeForm = (key, value) => {
    form.setFieldsValue({[key]: value});
  };
  const onFinishAdd = async (values) => {
    const params = new PlayerInfo(values);
    const result = await api.newPlayer(params);
    if (result?.succeed) {
      message.success("增加运动员成功！");
      sumbit();
    } else {
      message.error("增加运动员失败！");
    }
  };
  const onReset = useCallback(() => form.resetFields(), [form]);
  useEffect(() => onReset(), [onReset, visible]);
  return (
    <Modal
      title="新增运动员"
      visible={visible}
      onOk={sumbit}
      onCancel={close}
      footer={null}
    >
      <Form {...layout} form={form} name="control-hooks" onFinish={onFinishAdd}>
        <Form.Item name="name" label="姓名" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("name", e.target.value)}/>
        </Form.Item>
        <Form.Item name="country" label="国家" rules={[{required: true}]}>
          <Input onChange={e => onChangeForm("country", e.target.value)}/>
        </Form.Item>

        <Form.Item
          name="faceType0"
          label="反手胶面"
          rules={[{required: true}]}
        >
          <FaceTypeSelect placeholder="请选择反手胶面"
                          onChange={value => onChangeForm("faceType0", value)}/>
        </Form.Item>
        <Form.Item
          name="faceType1"
          label="正手胶面"
          rules={[{required: true}]}
        >
          <FaceTypeSelect placeholder="请选择正手胶面"
                          onChange={value => onChangeForm("faceType1", value)}/>
        </Form.Item>

        <Form.Item
          name="isRight"
          label="惯用右手"
          rules={[{required: true}]}
          initialValue={true}
        >
          <HandSwitch onChange={value => onChangeForm("isRight", value)}/>
        </Form.Item>
        <Form.Item
          name="isStraight"
          label="惯用直拍"
          initialValue={true}
          rules={[{required: true}]}
        >
          <HoldSwitch onChange={value => onChangeForm("isStraight", value)}/>
        </Form.Item>

        <Form.Item {...tailLayout}>
          <Button type="primary" htmlType="submit" style={{margin: "0 20px"}}>
            新增
          </Button>
          <Button
            htmlType="button"
            onClick={onReset}
            style={{margin: "0 20px"}}
          >
            重置
          </Button>
        </Form.Item>
      </Form>
    </Modal>
  );
}
