import React, {useEffect, useMemo, useState} from "react";
import {Button, Input, message} from "antd";
import TableList from "./table";
import "./index.css";
import AddMatch from "./add";
import api, {errorInfo} from "../../../api/api";
import dataStore from "../../../store/DataStore";

const {Search} = Input;

export default function Match() {
  const [tableData, setTableData] = useState([]);
  const [originData, setOriginData] = useState([]);
  const [showAdd, setShowAdd] = useState(false);
  const getList = async () => {
    try {
      const result = await api.getUserList();
      setTableData(result);
      setOriginData(result);
    } catch (e) {
      message.error(errorInfo(e));
    }
  };
  const close = () => setShowAdd(false);
  const filterTable = (key, values) => {
    if (values) {
      const filteredData = originData.filter(item => item[key] === values);
      setTableData(filteredData);
    } else {
      setTableData(originData);
    }
  };
  useEffect(() => getList(), []);
  const permissions = useMemo(() => originData[0] ? Object.keys(originData[0].permissions) : [], [originData]);
  return (
    <>{!dataStore.onlyPlayer?<>
      <div className="header-content">用户管理</div>
      <div className="main-content">
        <div className="match-btns">
          <Search
              className="item"
              placeholder="输入用户名"
              style={{width: 200}}
              onSearch={value => filterTable("username", value)}
          />

          <Button
              className="item"
              type="primary"
              onClick={() => setShowAdd(true)}
          >
            新增用户
          </Button>
        </div>
        <TableList data={tableData} changePage={getList}/>
      </div>

      {showAdd && <AddMatch
          visible={showAdd}
          close={close}
          permissions={permissions}
          sumbit={() => {
            close();
            getList();
          }}
      />}</>:null}

    </>
  );
}
